<template>
    <div>
        <div>
            <img v-for="(card, cardIndex) in dealerHand.cards"
                 :key="cardIndex"
                 :class="{ card__doubled: dealerHand.isDoubled }"
                 :src="getCardImage(card)"
                 :style="getDealerCardPosition(cardIndex, dealerHand.place)"
                 class="card image"
            />

            <img v-if="!dealerHand.isEnd"
                 :src="lastCardImage"

                 :style="getDealerLastCardPosition()"
                 class="card image"
            />
        </div>

        <!-- Отрисовка очков дилера -->
        <div :style="getDealerPointsPosition()"
             class="points__wrapper"
        >
            <img :src="pointsImage"
                 class="points__bg"
            />

            <span class="points__text">
            <template v-if="dealerHand.isBlackJack">
                BJ
            </template>
            <template v-else>
                <template v-if="dealerHand.secondValue !== 0">
                {{ dealerHand.secondValue }} /
                </template>
                {{ dealerHand.firstValue }}
            </template>
            </span>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        dealerHand: {
            required: true,
            type: Object,
            default() {
                return {
                    firstValue: null,
                    secondValue: null,
                    isEnd: null,
                    isBlackJack: null,
                    cards: [{
                        cost: null,
                        suit: null,
                        firstValue: null,
                        secondValue: null
                    }],
                }
            }
        }
    },
    data() {
        return {
            cardView: 30,
            card: {
                width: 90,
                height: 120,
            }
        }
    },
    computed: {
        pointsImage() {
            return require('@/assets/games/blackjack/blackjack/points_bg.png');
        },
        lastCardImage() {
            return require('@/assets/games/blackjack/blackjack/card_bg.png');
        }
    },
    methods: {
        getCardImage(card) {
            let imageName = `${card.cost}${card.suit}`;
            return require(`@/assets/games/blackjack/blackjack/cards/${imageName}.png`);
        },
        getDealerCardPosition(cardNumber) {
            let left = this.cardView * cardNumber;

            return {
                left: `${left}px`
            };
        },
        getDealerLastCardPosition() {
            let left = this.cardView * this.dealerHand.cards.length;

            return {
                left: `${left}px`
            };
        },
        getDealerPointsPosition() {
            let left = 0, top = 0;

            left = this.cardView;
            top = -(this.card.height * 0.3);

            return {
                left: `${left}px`,
                top: `${top}px`,
            };
        }
    }
}
</script>

<style scoped>
.card {
    position: absolute;
    width: 90px;
    height: 120px;
    background: transparent;
    border: 0;
}

.points__wrapper {
    position: absolute;
    width: 51px;
    height: 30px;
    background: transparent;
}

.points__bg {
    width: 51px;
    height: 30px;
}

.points__text {
    font-weight: bold;
    font-size: 12px;
    color: white;
    cursor: default;
    white-space: nowrap;

    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>
